import { useTheme } from "../../../hooks";
import { CatLink, SponsorSection } from "../../../styles/atoms";
import I1 from "../../../assets/22.jpg";
import I2 from "../../../assets/25.jpg";
import I6 from "../../../assets/50.jpg";
import React from "react";

function Deals () {
  const { theme } = useTheme();

  const images = [
    {
      id: '3',
      title: "Fêtes autorisées",
      img: I6,
      link: `/results/q=?have_party=1`
    },
    {
      id: '1',
      title: "Réservation gratuite", 
      img: I1,
      link: `/results/q=?free_booking=1`
    }, 
    {
      id: '2',
      title: "Accessible aux handicapés", 
      img: I2,
      link: `/results/q=?with_disability=1`
    }
  ];

  return (
    <SponsorSection theme={theme}>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-stretch">
        {images.map(({ id, title, img, link }) => (
          <CatLink to={link} className="col" key={id} aria-label={title}>
            <div 
              className="text-bg-dark rounded-4 position-relative" 
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)), url(${img})`,
                backgroundPosition: 'center center', 
                backgroundSize: 'cover',
                height: '300px'
              }}
            >
              <div className="position-absolute bottom-0 start-0 p-3">
                <h4 className="lead-5">{title} <i className="bi bi-arrow-right-circle"></i></h4>
              </div>
            </div>
          </CatLink>
        ))}
      </div>
    </SponsorSection>
  );
};

export default Deals;
